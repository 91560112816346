var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip",
          value: _vm.tooltip,
          expression: "tooltip"
        }
      ],
      staticClass: "nb-spotlight-control"
    },
    [
      _c(
        "span",
        {
          class: {
            active:
              !_vm.thread.spotlight || _vm.thread.spotlight.type === "NONE"
          },
          on: {
            click: function($event) {
              return _vm.onClick("X")
            }
          }
        },
        [_vm._v("X")]
      ),
      _vm._v(" "),
      _vm.currentConfigs.isInnotation
        ? _c(
            "span",
            {
              class: {
                active:
                  _vm.thread.spotlight && _vm.thread.spotlight.type === "IN"
              },
              on: {
                click: function($event) {
                  return _vm.onClick("IN")
                }
              }
            },
            [_vm._v("~")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentConfigs.isInnotation
        ? _c(
            "span",
            {
              class: {
                active:
                  _vm.thread.spotlight && _vm.thread.spotlight.type === "ABOVE"
              },
              on: {
                click: function($event) {
                  return _vm.onClick("ABOVE")
                }
              }
            },
            [_vm._v("↑")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentConfigs.isInnotation
        ? _c(
            "span",
            {
              class: {
                active:
                  _vm.thread.spotlight && _vm.thread.spotlight.type === "BELLOW"
              },
              on: {
                click: function($event) {
                  return _vm.onClick("BELLOW")
                }
              }
            },
            [_vm._v("↓")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentConfigs.isInnotation
        ? _c(
            "span",
            {
              class: {
                active:
                  _vm.thread.spotlight && _vm.thread.spotlight.type === "LEFT"
              },
              on: {
                click: function($event) {
                  return _vm.onClick("LEFT")
                }
              }
            },
            [_vm._v("←")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentConfigs.isInnotation
        ? _c(
            "span",
            {
              class: {
                active:
                  _vm.thread.spotlight && _vm.thread.spotlight.type === "RIGHT"
              },
              on: {
                click: function($event) {
                  return _vm.onClick("RIGHT")
                }
              }
            },
            [_vm._v("→")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentConfigs.isMarginalia
        ? _c(
            "span",
            {
              class: {
                active:
                  _vm.thread.spotlight && _vm.thread.spotlight.type === "MARGIN"
              },
              on: {
                click: function($event) {
                  return _vm.onClick("MARGIN")
                }
              }
            },
            [_vm._v("Ɱ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.currentConfigs.isEmphasize
        ? _c(
            "span",
            {
              class: {
                active:
                  _vm.thread.spotlight && _vm.thread.spotlight.type === "EM"
              },
              on: {
                click: function($event) {
                  return _vm.onClick("EM")
                }
              }
            },
            [_vm._v("❖")]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }