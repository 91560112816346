var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showSpotlights
    ? _c(
        "div",
        { attrs: { id: "nb-marginalias" } },
        _vm._l(_vm.marginalias, function(thread) {
          return _c("nb-marginalia", {
            key: _vm.key(thread),
            attrs: {
              thread: thread,
              "thread-selected": _vm.threadSelected,
              user: _vm.user,
              "threads-hovered": _vm.threadsHovered,
              activeClass: _vm.activeClass
            },
            on: {
              "log-nb": _vm.onLogNb,
              "select-thread": _vm.onSelectThread,
              "hover-thread": function($event) {
                return _vm.$emit("hover-thread", thread)
              },
              "unhover-thread": function($event) {
                return _vm.$emit("unhover-thread", thread)
              }
            }
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }