var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nb-sidebar", attrs: { id: "nb-sidebar" } },
    [
      _c("nav-bar", {
        attrs: { me: _vm.user },
        on: {
          logout: function($event) {
            return _vm.$emit("logout")
          }
        }
      }),
      _vm._v(" "),
      _vm._m(0)
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "nb-no-access-msg" }, [
      _c("h1", [_vm._v("You don't have access!")]),
      _vm._v(" "),
      _c("p", [
        _vm._v(
          "website doesn't seem to be part of any of your classes. Please make sure your enrolled to your class and using the correct URL. Contact your instructor for more informaiton."
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }