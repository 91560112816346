var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "g",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: {
                content: _vm.getTooltipContent()
              },
              expression: "{\n        content: getTooltipContent(),\n    }"
            }
          ],
          staticClass: "nb-highlight",
          style: _vm.style,
          on: {
            click: function($event) {
              return _vm.onClick()
            },
            mouseenter: function($event) {
              return _vm.onHover(true)
            },
            mouseleave: function($event) {
              return _vm.onHover(false)
            }
          }
        },
        _vm._l(_vm.bounds.boxes, function(box, index) {
          return _c(
            "rect",
            {
              key: index,
              attrs: {
                x: box.left + _vm.bounds.offsetX,
                y: box.top + _vm.bounds.offsetY,
                height: box.height,
                width: box.width
              }
            },
            [
              _vm.showRecentActivityAnimation
                ? _c("animate", {
                    attrs: {
                      attributeType: "XML",
                      attributeName: "fill",
                      values: "#ffffff;#4a2270D9;#ffffff;#ffffff",
                      dur: "5.0s",
                      repeatCount: "indefinite"
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.showTypingActivityAnimation
                ? _c("animate", {
                    attrs: {
                      attributeType: "XML",
                      attributeName: "fill",
                      values: "#ffffff;#4a2270D9;#ffffff;#ffffff",
                      dur: "2.0s",
                      repeatCount: "indefinite"
                    }
                  })
                : _vm._e()
            ]
          )
        }),
        0
      )
    : _vm.isHidden
    ? _c(
        "g",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip.right",
              value: { content: _vm.getHiddenTooltipContent() },
              expression: "{content: getHiddenTooltipContent()}",
              modifiers: { right: true }
            }
          ],
          staticClass: "nb-highlight",
          style: _vm.style,
          on: {
            click: function($event) {
              return _vm.$emit("select-thread", _vm.thread)
            },
            mouseenter: function($event) {
              return _vm.onHover(true)
            },
            mouseleave: function($event) {
              return _vm.onHover(false)
            }
          }
        },
        _vm._l(_vm.bounds.boxes, function(box, index) {
          return _c("rect", {
            key: index,
            attrs: {
              x: _vm.bounds.offsetX,
              y: box.top + _vm.bounds.offsetY,
              height: box.height,
              width: "20"
            }
          })
        }),
        0
      )
    : _c(
        "g",
        {
          staticClass: "nb-highlight",
          style: _vm.style,
          on: {
            click: function($event) {
              return _vm.$emit("select-thread", _vm.thread)
            },
            mouseenter: function($event) {
              return _vm.onHover(true)
            },
            mouseleave: function($event) {
              return _vm.onHover(false)
            }
          }
        },
        _vm._l(_vm.bounds.boxes, function(box, index) {
          return _c("rect", {
            key: index,
            attrs: {
              x: _vm.bounds.offsetX,
              y: box.top + _vm.bounds.offsetY,
              height: box.height,
              width: "20"
            }
          })
        }),
        0
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }