var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nb-audio-editor" },
    [
      _vm.showError
        ? [
            _c("span", [
              _vm._v(
                "Your browser doesn’t support audio recording or you’ve blocked microphone access."
              )
            ])
          ]
        : [
            _c("vue-dictaphone", {
              attrs: { "mime-type": "audio/mp3" },
              on: {
                stop: function($event) {
                  return _vm.handleRecording($event)
                },
                error: _vm.handleError
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var isRecording = ref.isRecording
                    var startRecording = ref.startRecording
                    var stopRecording = ref.stopRecording
                    var deleteRecording = ref.deleteRecording
                    return [
                      !isRecording
                        ? _c(
                            "button",
                            { on: { click: startRecording } },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "ae-icon",
                                attrs: { icon: "circle" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      isRecording
                        ? _c(
                            "button",
                            { on: { click: stopRecording } },
                            [
                              _c("font-awesome-icon", {
                                staticClass: "ae-icon",
                                attrs: { icon: "stop" }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      isRecording
                        ? _c("vue-dictaphone-spectrum-analyser")
                        : _vm._e()
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _vm.isRecorded
              ? [_c("audio", { attrs: { src: _vm.audioSource, controls: "" } })]
              : _vm._e(),
            _vm._v(" "),
            false && !_vm.isRecording && _vm.isRecorded
              ? _c(
                  "button",
                  { on: { click: _vm.handleDeleteRecording } },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "ae-icon",
                      attrs: { icon: "trash" }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }