var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible"
        }
      ],
      staticClass: "editor-view"
    },
    [
      _c("div", { staticClass: "header" }, [_vm._v(_vm._s(_vm.header))]),
      _vm._v(" "),
      _vm.currentConfigs.isCommentMediaAudio
        ? _c(
            "tabs",
            [
              _c(
                "tab",
                {
                  class: { active: _vm.activeTab === "text" },
                  on: {
                    click: function($event) {
                      return _vm.handleTabChange("text")
                    }
                  }
                },
                [
                  _c(
                    "span",
                    [
                      _c("font-awesome-icon", {
                        staticClass: "ev-icon",
                        attrs: { icon: "i-cursor" }
                      })
                    ],
                    1
                  )
                ]
              ),
              _vm._v(" "),
              _vm.currentConfigs.isCommentMediaAudio
                ? _c(
                    "tab",
                    {
                      class: { active: _vm.activeTab === "audio" },
                      on: {
                        click: function($event) {
                          return _vm.handleTabChange("audio")
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        [
                          _c("font-awesome-icon", {
                            staticClass: "ev-icon",
                            attrs: { icon: "microphone" }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              false
                ? _c(
                    "tab",
                    {
                      class: { active: _vm.activeTab === "video" },
                      on: {
                        click: function($event) {
                          return _vm.handleTabChange("video")
                        }
                      }
                    },
                    [
                      _c(
                        "span",
                        [
                          _c("font-awesome-icon", {
                            staticClass: "ev-icon",
                            attrs: { icon: "video" }
                          })
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "area", class: { active: _vm.activeTab === "text" } },
        [
          _c("text-editor", {
            key: _vm.key,
            attrs: {
              toolbar: _vm.toolbar,
              placeholder: _vm.placeholder,
              "initial-content": _vm.initialContent,
              users: _vm.users,
              hashtags: _vm.hashtags
            },
            on: {
              "text-change": _vm.onTextChange,
              "thread-typing": _vm.onThreadTyping,
              "thread-stop-typing": _vm.onThreadStopTyping
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.currentConfigs.isCommentMediaAudio
        ? _c(
            "div",
            {
              staticClass: "area",
              class: { active: _vm.activeTab === "audio" }
            },
            [
              _vm.activeTab === "audio" || _vm.mediaBlob
                ? _c("audio-editor", { on: { "audio-stop": _vm.onAudioStop } })
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      false
        ? _c(
            "div",
            {
              staticClass: "area",
              class: { active: _vm.activeTab === "video" }
            },
            [_vm._v("\n    Video\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c("div", { staticClass: "selections" }, [
          _vm._v("\n      Post to\n      "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.visibility,
                  expression: "visibility"
                }
              ],
              on: {
                change: [
                  function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.visibility = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function($event) {
                    return _vm.onVisibilityChange($event)
                  }
                ]
              }
            },
            _vm._l(_vm.currentVisibilityOptions, function(option) {
              return _c(
                "option",
                { key: option.value, domProps: { value: option.value } },
                [_vm._v("\n          " + _vm._s(option.text) + "\n        ")]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c("br"),
          _vm._v("as\n      "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.anonymity,
                  expression: "anonymity"
                }
              ],
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.anonymity = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            _vm._l(_vm.anonymityOptions, function(option) {
              return _c(
                "option",
                {
                  key: option.value,
                  attrs: { disabled: option.disabled },
                  domProps: { value: option.value }
                },
                [_vm._v("\n          " + _vm._s(option.text) + "\n        ")]
              )
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "checkbox-buttons" }, [
          _vm.currentConfigs.isExpClass
            ? _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.upvotedByMe,
                    expression: "upvotedByMe"
                  }
                ],
                attrs: { type: "checkbox", id: "draft-request-reply" },
                domProps: {
                  checked: Array.isArray(_vm.upvotedByMe)
                    ? _vm._i(_vm.upvotedByMe, null) > -1
                    : _vm.upvotedByMe
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.upvotedByMe,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.upvotedByMe = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.upvotedByMe = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.upvotedByMe = $$c
                    }
                  }
                }
              })
            : _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.replyRequested,
                    expression: "replyRequested"
                  }
                ],
                attrs: { type: "checkbox", id: "draft-request-reply" },
                domProps: {
                  checked: Array.isArray(_vm.replyRequested)
                    ? _vm._i(_vm.replyRequested, null) > -1
                    : _vm.replyRequested
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.replyRequested,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.replyRequested = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.replyRequested = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.replyRequested = $$c
                    }
                  }
                }
              }),
          _vm._v(" "),
          _c("label", { attrs: { for: "draft-request-reply" } }, [
            _vm._v(
              _vm._s(
                _vm.currentConfigs.isExpClass
                  ? "Looking for Classmates' Perspectives"
                  : "Request replies"
              )
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "checkbox-buttons" }, [
          _c("div", { staticClass: "buttons" }, [
            _c(
              "button",
              {
                staticClass: "cancel",
                attrs: { disabled: _vm.isSubmitting },
                on: { click: _vm.cancel }
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "submit",
                attrs: { disabled: _vm.isSubmitting || !_vm.canSubmit },
                on: { click: _vm.submit }
              },
              [
                _vm.isSubmitting
                  ? _c("span", [_vm._v("Submitting...")])
                  : _c("span", [_vm._v("Submit")])
              ]
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "nb-emoji-attr" }, [
          _vm._v(
            "All emojis designed by OpenMoji – the open-source emoji and icon project. License: CC BY-SA 4.0"
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }