var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-view", staticStyle: { "margin-top": "5px" } },
    [
      _c(
        "header",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.tooltipType,
              expression: "tooltipType"
            }
          ],
          staticClass: "card-header",
          on: {
            click: function($event) {
              _vm.isCollapsed = !_vm.isCollapsed
            }
          }
        },
        [
          _c("p", { staticClass: "card-header-title" }, [
            _vm._v(
              _vm._s(_vm.title) +
                " (" +
                _vm._s(_vm.numberUnseen) +
                " / " +
                _vm._s(_vm.totalCount) +
                " unread)"
            )
          ]),
          _vm._v(" "),
          _c(
            "a",
            { staticClass: "button card-header-icon collapse-button" },
            [
              !_vm.isCollapsed
                ? _c("font-awesome-icon", { attrs: { icon: "chevron-up" } })
                : _vm._e(),
              _vm._v(" "),
              _vm.isCollapsed
                ? _c("font-awesome-icon", { attrs: { icon: "chevron-down" } })
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      !_vm.isCollapsed
        ? _c("div", [
            _c("div", { staticClass: "list-header" }, [
              _c("span", { staticClass: "count" }, [
                _vm._v("\n        " + _vm._s(_vm.totalLabel) + "\n      ")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "icons-left" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.notificationsMuted
                          ? "Unmute notifications"
                          : "Mute notifications",
                        expression:
                          "notificationsMuted ? 'Unmute notifications' : 'Mute notifications'"
                      }
                    ],
                    on: { click: _vm.toggleMute }
                  },
                  [
                    _vm.notificationsMuted
                      ? _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: "bell-slash" }
                        })
                      : _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: "bell" }
                        })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Open notifications window",
                        expression: "'Open notifications window'"
                      }
                    ],
                    on: {
                      click: function($event) {
                        return _vm.$emit("undock-draggable-notifications")
                      }
                    }
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "icon",
                      attrs: { icon: "clone" }
                    })
                  ],
                  1
                )
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "notification-table" },
              [
                _vm._l(_vm.onlineNotifications, function(notification, index) {
                  return _c("notification-row", {
                    key:
                      _vm.onlineNotifications[
                        _vm.onlineNotifications.length - 1 - index
                      ],
                    attrs: {
                      notification:
                        _vm.onlineNotifications[
                          _vm.onlineNotifications.length - 1 - index
                        ],
                      "thread-selected": _vm.threadSelected,
                      "notification-selected": _vm.notificationSelected,
                      "threads-hovered": _vm.threadsHovered,
                      activeClass: _vm.activeClass,
                      user: _vm.user
                    },
                    on: {
                      "select-notification": _vm.onSelectNotification,
                      "hover-thread": _vm.onHoverNotification,
                      "unhover-thread": _vm.onUnhoverNotification
                    }
                  })
                }),
                _vm._v(" "),
                _vm.offlineNotifications.length > 0
                  ? _c("h4", { attrs: { id: "olderNotificationHeading" } }, [
                      _c(
                        "span",
                        { attrs: { id: "olderNotificationSpanWhite" } },
                        [
                          _c("font-awesome-icon", {
                            attrs: { icon: "chevron-down" }
                          }),
                          _vm._v("\n          Older Notifications\n          "),
                          _c("font-awesome-icon", {
                            attrs: { icon: "chevron-down" }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.offlineNotifications, function(notification, index) {
                  return _c("notification-row", {
                    key:
                      _vm.offlineNotifications[
                        _vm.offlineNotifications.length - 1 - index
                      ],
                    attrs: {
                      notification:
                        _vm.offlineNotifications[
                          _vm.offlineNotifications.length - 1 - index
                        ],
                      "thread-selected": _vm.threadSelected,
                      "notification-selected": _vm.notificationSelected,
                      "threads-hovered": _vm.threadsHovered,
                      activeClass: _vm.activeClass,
                      user: _vm.user
                    },
                    on: {
                      "select-notification": _vm.onSelectNotification,
                      "hover-thread": _vm.onHoverNotification,
                      "unhover-thread": _vm.onUnhoverNotification
                    }
                  })
                })
              ],
              2
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }