var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "thread-view" },
    [
      _c(
        "div",
        { staticClass: "thread-header" },
        [
          _vm._v(
            "\n        " + _vm._s(_vm.numComments) + "\n         · \n        "
          ),
          _c("font-awesome-icon", { attrs: { icon: "question" } }),
          _vm._v(
            "\n        " +
              _vm._s(_vm.thread.countAllReplyReqs()) +
              "\n         · \n        "
          ),
          _vm.isEnabled
            ? _c("nb-spotlight-control", {
                attrs: {
                  thread: _vm.thread,
                  "current-configs": _vm.currentConfigs
                }
              })
            : _vm._e(),
          _vm._v(" "),
          false
            ? _c("div", { staticClass: "thread-header-arrows" }, [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Show previous thread in document",
                        expression: "'Show previous thread in document'"
                      }
                    ],
                    on: { click: _vm.onPrevComment }
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "chevron-circle-left" }
                    })
                  ],
                  1
                ),
                _vm._v("\n                 \n            "),
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Show next thread in document",
                        expression: "'Show next thread in document'"
                      }
                    ],
                    on: { click: _vm.onNextComment }
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "chevron-circle-right" }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("thread-comment", {
        attrs: {
          comment: _vm.thread,
          me: _vm.me,
          replyToComment: _vm.replyToComment,
          activeClass: _vm.activeClass,
          "thread-view-initiator": _vm.threadViewInitiator,
          myfollowing: _vm.myfollowing,
          "current-configs": _vm.currentConfigs
        },
        on: {
          "log-nb": _vm.onLogNb,
          "edit-comment": _vm.editComment,
          "delete-comment": _vm.deleteComment,
          "submit-small-comment": _vm.submitSmallComment,
          "draft-reply": _vm.draftReply,
          "follow-author": _vm.followAuthor,
          "unfollow-author": _vm.unfollowAuthor
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }