var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "list-view" }, [
    _c(
      "header",
      {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip",
            value: _vm.tooltipType,
            expression: "tooltipType"
          }
        ],
        staticClass: "card-header",
        on: {
          click: function($event) {
            _vm.isCollapsed = !_vm.isCollapsed
          }
        }
      },
      [
        _c("p", { staticClass: "card-header-title" }, [
          _vm._v(_vm._s(_vm.title))
        ]),
        _vm._v(" "),
        _c(
          "a",
          { staticClass: "button card-header-icon collapse-button" },
          [
            !_vm.isCollapsed
              ? _c("font-awesome-icon", { attrs: { icon: "chevron-up" } })
              : _vm._e(),
            _vm._v(" "),
            _vm.isCollapsed
              ? _c("font-awesome-icon", { attrs: { icon: "chevron-down" } })
              : _vm._e()
          ],
          1
        )
      ]
    ),
    _vm._v(" "),
    !_vm.isCollapsed
      ? _c("div", [
          _c("div", { staticClass: "list-control" }, [
            _c(
              "div",
              { staticClass: "list-control-header" },
              [
                _c("font-awesome-icon", {
                  staticClass: "icon",
                  attrs: { icon: "eye" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "list-control-controls" }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.showHighlights
                        ? "hide highlights"
                        : "show highlights",
                      expression:
                        "showHighlights ? 'hide highlights' : 'show highlights'"
                    }
                  ]
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "icon",
                    attrs: { icon: "highlighter" }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { on: { click: _vm.toggleHighlights } },
                    [
                      _vm.showHighlights
                        ? _c("font-awesome-icon", {
                            staticClass: "icon toggle-on",
                            attrs: { icon: "toggle-on" }
                          })
                        : _c("font-awesome-icon", {
                            staticClass: "icon",
                            attrs: { icon: "toggle-off" }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.showSpotlights
                        ? "hide spotlights"
                        : "show spotlights",
                      expression:
                        "showSpotlights ? 'hide spotlights' : 'show spotlights'"
                    }
                  ]
                },
                [
                  _c("font-awesome-icon", {
                    staticClass: "icon",
                    attrs: { icon: "sun" }
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { on: { click: _vm.toggleSpotlights } },
                    [
                      _vm.showSpotlights
                        ? _c("font-awesome-icon", {
                            staticClass: "icon toggle-on",
                            attrs: { icon: "toggle-on" }
                          })
                        : _c("font-awesome-icon", {
                            staticClass: "icon",
                            attrs: { icon: "toggle-off" }
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: "redraw highlights",
                      expression: "'redraw highlights'"
                    }
                  ]
                },
                [
                  _c(
                    "span",
                    { on: { click: _vm.onHandleRedrawHighlights } },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "icon",
                        attrs: { icon: "redo" }
                      })
                    ],
                    1
                  )
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "list-header" }, [
            _c("span", { staticClass: "count" }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value:
                        _vm.currentThreadsCount !== _vm.totalCount
                          ? "Filter applied"
                          : undefined,
                      expression:
                        "currentThreadsCount !== totalCount ? 'Filter applied' : undefined"
                    }
                  ],
                  class: {
                    filterdThreads: _vm.currentThreadsCount !== _vm.totalCount
                  }
                },
                [_vm._v(_vm._s(_vm.currentThreadsCount))]
              ),
              _vm._v(" of " + _vm._s(_vm.totalLabel) + "\n            ")
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "sort" }, [
              _vm._v("\n                Sort by:\n                "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.sortBy,
                      expression: "sortBy"
                    }
                  ],
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.sortBy = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                _vm._l(_vm.sortByOptions, function(option) {
                  return _c(
                    "option",
                    { key: option.value, domProps: { value: option.value } },
                    [
                      _vm._v(
                        "\n                        " +
                          _vm._s(option.text) +
                          "\n                    "
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "list-table", style: _vm.style },
            [
              _vm.stillGatheringThreads
                ? _c(
                    "div",
                    [
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "text-align": "center",
                            "font-family": "monospace",
                            color: "#666"
                          }
                        },
                        [_vm._v("Fetching Annotations...")]
                      ),
                      _vm._v(" "),
                      _c("tile", { attrs: { loading: "true" } })
                    ],
                    1
                  )
                : _vm.isEmptyAndFiltered
                ? _c("div", { staticClass: "empty-and-filterd" }, [
                    _c(
                      "div",
                      { staticClass: "filter-icon" },
                      [_c("font-awesome-icon", { attrs: { icon: "filter" } })],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "text" }, [
                      _vm._v(
                        "Filters are applied, edit filters to show more threads"
                      )
                    ]),
                    _vm._v(" "),
                    _c("button", { on: { click: _vm.onFilterShow } }, [
                      _vm._v("Open Filters")
                    ])
                  ])
                : _vm._l(_vm.sorted, function(thread) {
                    return _c("list-row", {
                      key: thread,
                      attrs: {
                        thread: thread,
                        "thread-selected": _vm.threadSelected,
                        "threads-hovered": _vm.threadsHovered,
                        "current-configs": _vm.currentConfigs,
                        activeClass: _vm.activeClass,
                        "show-sync-features": _vm.showSyncFeatures,
                        user: _vm.user,
                        myfollowing: _vm.myfollowing
                      },
                      on: {
                        "log-nb": _vm.onLogNb,
                        "select-thread": _vm.onSelectThread,
                        "hover-thread": function($event) {
                          return _vm.$emit("hover-thread", thread)
                        },
                        "unhover-thread": function($event) {
                          return _vm.$emit("unhover-thread", thread)
                        }
                      }
                    })
                  })
            ],
            2
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }