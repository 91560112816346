var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.thread.id,
      staticClass: "list-row",
      style: _vm.rowStyle,
      on: {
        mouseenter: _vm.onHoverNotification,
        mouseleave: _vm.onUnhoverNotification,
        click: _vm.onClickNotification
      }
    },
    [
      _c("div", { staticClass: "flags" }, [
        _vm.notification.type === "instructor"
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Comment has instructor comment",
                    expression: "'Comment has instructor comment'"
                  }
                ],
                staticClass: "icon-wrapper instructor"
              },
              [_vm._v("\n      i\n    ")]
            )
          : _vm._e(),
        _vm._v(" \n    "),
        _vm.notification.type === "question"
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Comment has a reply request",
                    expression: "'Comment has a reply request'"
                  }
                ],
                staticClass: "icon-wrapper reply-request"
              },
              [
                _c("font-awesome-icon", { attrs: { icon: "question" } }),
                _vm._v(" \n    ")
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.notification.type === "tag"
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Comment has your tag",
                    expression: "'Comment has your tag'"
                  }
                ],
                staticClass: "icon-wrapper tag"
              },
              [
                _c("font-awesome-icon", { attrs: { icon: "user-tag" } }),
                _vm._v(" \n    ")
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.notification.type === "recent"
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Recent comment",
                    expression: "'Recent comment'"
                  }
                ],
                staticClass: "icon-wrapper recent"
              },
              [
                _c("font-awesome-icon", { attrs: { icon: "history" } }),
                _vm._v(" \n    ")
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.notification.type === "reply"
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Comment reply",
                    expression: "'Comment reply'"
                  }
                ],
                staticClass: "icon-wrapper reply"
              },
              [
                _c("font-awesome-icon", { attrs: { icon: "reply" } }),
                _vm._v(" \n    ")
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { style: _vm.textStyle }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.authorName) +
              ": " +
              _vm._s(_vm.commentText) +
              "\n    "
          )
        ])
      ]),
      _vm._v(" "),
      _c("span", { style: _vm.timeTextStyle }, [
        _vm._v("\n    " + _vm._s(_vm.timeString) + "\n  ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }