var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showSpotlights
    ? _c(
        "div",
        [
          _vm._l(_vm.innotationsInline, function(thread) {
            return _c("nb-innotation-inline", {
              key: _vm.key(thread),
              attrs: {
                thread: thread,
                "thread-selected": _vm.threadSelected,
                user: _vm.user,
                activeClass: _vm.activeClass
              },
              on: {
                "log-nb": _vm.onLogNb,
                "select-thread": _vm.onSelectThread,
                "hover-innotation": function($event) {
                  return _vm.$emit("hover-innotation", thread)
                },
                "unhover-innotation": function($event) {
                  return _vm.$emit("unhover-innotation", thread)
                }
              }
            })
          }),
          _vm._v(" "),
          _vm._l(_vm.innotationsBlock, function(thread) {
            return _c("nb-innotation-block", {
              key: _vm.key(thread),
              attrs: {
                thread: thread,
                "thread-selected": _vm.threadSelected,
                user: _vm.user,
                activeClass: _vm.activeClass
              },
              on: {
                "log-nb": _vm.onLogNb,
                "select-thread": _vm.onSelectThread,
                "hover-innotation": function($event) {
                  return _vm.$emit("hover-innotation", thread)
                },
                "unhover-innotation": function($event) {
                  return _vm.$emit("unhover-innotation", thread)
                }
              }
            })
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }