var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filter-view", style: _vm.filterViewStyle }, [
    _c(
      "div",
      { staticClass: "filter-header" },
      [
        _c("search-bar", {
          attrs: { users: _vm.users, hashtags: _vm.hashtags },
          on: {
            "dropdown-change": _vm.onSearchOptionChange,
            "text-change": _vm.onTextChange
          }
        }),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.filterComments.includes("me")
                  ? "clear filter"
                  : "show my comments",
                expression:
                  "filterComments.includes('me') ? 'clear filter' : 'show my comments'"
              }
            ],
            on: { click: _vm.toggleFilterMyComments }
          },
          [
            _vm.filterComments.includes("me")
              ? _c("font-awesome-icon", {
                  staticClass: "icon active",
                  attrs: { icon: "comment" }
                })
              : _c("font-awesome-icon", {
                  staticClass: "icon",
                  attrs: { icon: "comment" }
                })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.filterUserTags.includes("me")
                  ? "clear filter"
                  : "show comments I'm tagged in",
                expression:
                  "filterUserTags.includes('me') ? 'clear filter' : `show comments I'm tagged in`"
              }
            ],
            on: { click: _vm.toggleFilterMyTags }
          },
          [
            _vm.filterUserTags.includes("me")
              ? _c("font-awesome-icon", {
                  staticClass: "icon active",
                  attrs: { icon: "user-tag" }
                })
              : _c("font-awesome-icon", {
                  staticClass: "icon",
                  attrs: { icon: "user-tag" }
                })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.filterBookmarks ? "clear filter" : "show bookmarked",
                expression:
                  "filterBookmarks ? 'clear filter' : 'show bookmarked'"
              }
            ],
            on: { click: _vm.toggleFilterBookmarks }
          },
          [
            _vm.filterBookmarks
              ? _c("font-awesome-icon", {
                  staticClass: "icon active",
                  attrs: { icon: "bookmark" }
                })
              : _c("font-awesome-icon", {
                  staticClass: "icon",
                  attrs: { icon: "bookmark" }
                })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-popover",
          {
            staticClass: "overflow-menu",
            attrs: {
              popoverClass: "filter-options-wrapper",
              container: "#nb-app-wrapper",
              open: _vm.filterVisible
            },
            on: { hide: _vm.onFilterHide }
          },
          [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.filterVisible ? "hide" : "show all filters",
                    expression: "filterVisible ? 'hide' : 'show all filters'"
                  }
                ],
                staticClass: "tooltip-target",
                on: { click: _vm.onToggleFilters }
              },
              [
                _vm.filterVisible
                  ? _c("font-awesome-icon", {
                      staticClass: "icon",
                      attrs: { icon: "times-circle" }
                    })
                  : _c("font-awesome-icon", {
                      staticClass: "icon",
                      attrs: { icon: "filter" }
                    })
              ],
              1
            ),
            _vm._v(" "),
            _c("template", { slot: "popover" }, [
              _c("div", { staticClass: "filter-options" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("Hashtags/emojis")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "hashtags" },
                  _vm._l(_vm.hashtags, function(hashtag) {
                    return _c("div", { key: hashtag.id }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.filterHashtags,
                            expression: "filterHashtags"
                          }
                        ],
                        attrs: {
                          type: "checkbox",
                          id: "filter-hashtag-" + hashtag.id
                        },
                        domProps: {
                          value: hashtag.id,
                          checked: Array.isArray(_vm.filterHashtags)
                            ? _vm._i(_vm.filterHashtags, hashtag.id) > -1
                            : _vm.filterHashtags
                        },
                        on: {
                          change: [
                            function($event) {
                              var $$a = _vm.filterHashtags,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = hashtag.id,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.filterHashtags = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.filterHashtags = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.filterHashtags = $$c
                              }
                            },
                            function($event) {
                              return _vm.onFilterChange("hashtags")
                            }
                          ]
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        { attrs: { for: "filter-hashtag-" + hashtag.id } },
                        [
                          _c("img", {
                            attrs: {
                              src:
                                _vm.pluginHostURL +
                                "/emoji/" +
                                hashtag.emoji +
                                ".png"
                            }
                          }),
                          _vm._v(" "),
                          _c("span", {
                            domProps: { innerHTML: _vm._s("" + hashtag.value) }
                          })
                        ]
                      )
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _vm._v("Max. # of threads")
                ]),
                _vm._v(" "),
                _c("div", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.maxThreads,
                        expression: "maxThreads"
                      }
                    ],
                    attrs: {
                      type: "number",
                      id: "max-threads",
                      placeholder: "n/a",
                      min: "0"
                    },
                    domProps: { value: _vm.maxThreads },
                    on: {
                      keypress: function(event) {
                        return _vm.validateNumber(event)
                      },
                      change: function($event) {
                        return _vm.onFilterChange("max-threads")
                      },
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.maxThreads = $event.target.value
                      }
                    }
                  })
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(
                      _vm.currentConfigs.isExpClass
                        ? "Looking for Classmates' Perspectives"
                        : "Upvotes"
                    )
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "upvotes" }, [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterUpvotes,
                          expression: "filterUpvotes"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "anyone-upvotes",
                        value: "anyone"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.filterUpvotes)
                          ? _vm._i(_vm.filterUpvotes, "anyone") > -1
                          : _vm.filterUpvotes
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.filterUpvotes,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "anyone",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.filterUpvotes = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.filterUpvotes = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.filterUpvotes = $$c
                            }
                          },
                          function($event) {
                            return _vm.onFilterChange("upvotes")
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "anyone-upvotes" } }, [
                      _vm._v("\n                anyone\n              ")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterUpvotes,
                          expression: "filterUpvotes"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "my-upvotes",
                        value: "me"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.filterUpvotes)
                          ? _vm._i(_vm.filterUpvotes, "me") > -1
                          : _vm.filterUpvotes
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.filterUpvotes,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "me",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.filterUpvotes = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.filterUpvotes = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.filterUpvotes = $$c
                            }
                          },
                          function($event) {
                            return _vm.onFilterChange("upvotes")
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "my-upvotes" } }, [
                      _vm._v("\n                me\n              ")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [_vm._v("Users tagged")]),
                _vm._v(" "),
                _c("div", { staticClass: "user-tags" }, [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterUserTags,
                          expression: "filterUserTags"
                        }
                      ],
                      attrs: { type: "checkbox", id: "me-tagged", value: "me" },
                      domProps: {
                        checked: Array.isArray(_vm.filterUserTags)
                          ? _vm._i(_vm.filterUserTags, "me") > -1
                          : _vm.filterUserTags
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.filterUserTags,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "me",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.filterUserTags = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.filterUserTags = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.filterUserTags = $$c
                            }
                          },
                          function($event) {
                            return _vm.onFilterChange("user-tags")
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "me-tagged" } }, [
                      _vm._v("\n                me\n              ")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _vm._v("Comments and Replies")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "comments-replies" }, [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterComments,
                          expression: "filterComments"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "my-comments",
                        value: "me"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.filterComments)
                          ? _vm._i(_vm.filterComments, "me") > -1
                          : _vm.filterComments
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.filterComments,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "me",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.filterComments = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.filterComments = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.filterComments = $$c
                            }
                          },
                          function($event) {
                            return _vm.onFilterChange("comments")
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "my-comments" } }, [
                      _vm._v("\n                me\n              ")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterComments,
                          expression: "filterComments"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "following-comments",
                        value: "following"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.filterComments)
                          ? _vm._i(_vm.filterComments, "following") > -1
                          : _vm.filterComments
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.filterComments,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "following",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.filterComments = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.filterComments = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.filterComments = $$c
                            }
                          },
                          function($event) {
                            return _vm.onFilterChange("comments")
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "following-comments" } }, [
                      _vm._v("\n                following\n              ")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterComments,
                          expression: "filterComments"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "instructor-comments",
                        value: "instructor"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.filterComments)
                          ? _vm._i(_vm.filterComments, "instructor") > -1
                          : _vm.filterComments
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.filterComments,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "instructor",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.filterComments = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.filterComments = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.filterComments = $$c
                            }
                          },
                          function($event) {
                            return _vm.onFilterChange("comments")
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "instructor-comments" } }, [
                      _vm._v(
                        "\n                instructors/endorsed\n              "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterComments,
                          expression: "filterComments"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "ta-comments",
                        value: "ta"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.filterComments)
                          ? _vm._i(_vm.filterComments, "ta") > -1
                          : _vm.filterComments
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.filterComments,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "ta",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.filterComments = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.filterComments = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.filterComments = $$c
                            }
                          },
                          function($event) {
                            return _vm.onFilterChange("comments")
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "ta-comments" } }, [
                      _vm._v("\n                TA\n              ")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [_vm._v("Reply Requests")]),
                _vm._v(" "),
                _c("div", { staticClass: "reply-requests" }, [
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterReplyReqs,
                          expression: "filterReplyReqs"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "anyone-reply-reqs",
                        value: "anyone"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.filterReplyReqs)
                          ? _vm._i(_vm.filterReplyReqs, "anyone") > -1
                          : _vm.filterReplyReqs
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.filterReplyReqs,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "anyone",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.filterReplyReqs = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.filterReplyReqs = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.filterReplyReqs = $$c
                            }
                          },
                          function($event) {
                            return _vm.onFilterChange("reply-reqs")
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "anyone-reply-reqs" } }, [
                      _vm._v("\n                anyone\n              ")
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterReplyReqs,
                          expression: "filterReplyReqs"
                        }
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "my-reply-reqs",
                        value: "me"
                      },
                      domProps: {
                        checked: Array.isArray(_vm.filterReplyReqs)
                          ? _vm._i(_vm.filterReplyReqs, "me") > -1
                          : _vm.filterReplyReqs
                      },
                      on: {
                        change: [
                          function($event) {
                            var $$a = _vm.filterReplyReqs,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = "me",
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  (_vm.filterReplyReqs = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.filterReplyReqs = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.filterReplyReqs = $$c
                            }
                          },
                          function($event) {
                            return _vm.onFilterChange("reply-reqs")
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "my-reply-reqs" } }, [
                      _vm._v("\n                me\n              ")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "title" }, [
                  _vm._v("\n            Others\n          ")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "others" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filterBookmarks,
                        expression: "filterBookmarks"
                      }
                    ],
                    attrs: {
                      type: "checkbox",
                      id: "bookmarks",
                      value: "bookmarks"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.filterBookmarks)
                        ? _vm._i(_vm.filterBookmarks, "bookmarks") > -1
                        : _vm.filterBookmarks
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.filterBookmarks,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "bookmarks",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.filterBookmarks = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.filterBookmarks = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.filterBookmarks = $$c
                          }
                        },
                        function($event) {
                          return _vm.onFilterChange("bookmarks")
                        }
                      ]
                    }
                  }),
                  _vm._v(" "),
                  _c("label", { attrs: { for: "bookmarks" } }, [
                    _vm._v("\n              bookmarked\n            ")
                  ])
                ]),
                _vm._v(" "),
                _vm.showAdvanced
                  ? _c("div", { staticClass: "title" }, [_vm._v("Advanced")])
                  : _vm._e(),
                _vm._v(" "),
                _vm.showAdvanced
                  ? _c("div", { staticClass: "advanced" }, [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: "Pending and resolved",
                              expression: "'Pending and resolved'"
                            }
                          ]
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filterReplyReqs,
                                expression: "filterReplyReqs"
                              }
                            ],
                            staticStyle: { width: "15px" },
                            attrs: {
                              type: "checkbox",
                              id: "ta-reply-reqs",
                              value: "ta"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.filterReplyReqs)
                                ? _vm._i(_vm.filterReplyReqs, "ta") > -1
                                : _vm.filterReplyReqs
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a = _vm.filterReplyReqs,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "ta",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.filterReplyReqs = $$a.concat([
                                          $$v
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.filterReplyReqs = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.filterReplyReqs = $$c
                                  }
                                },
                                function($event) {
                                  return _vm.onFilterChange("reply-reqs")
                                }
                              ]
                            }
                          }),
                          _vm._v(" "),
                          _c("label", { attrs: { for: "ta-reply-reqs" } }, [
                            _vm._v(
                              "\n                All TA reply requests\n              "
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value:
                                "TA reply request without instructor reply",
                              expression:
                                "'TA reply request without instructor reply'"
                            }
                          ]
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filterReplyReqs,
                                expression: "filterReplyReqs"
                              }
                            ],
                            staticStyle: { width: "15px" },
                            attrs: {
                              type: "checkbox",
                              id: "unhandled-reply-reqs",
                              value: "unhandled"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.filterReplyReqs)
                                ? _vm._i(_vm.filterReplyReqs, "unhandled") > -1
                                : _vm.filterReplyReqs
                            },
                            on: {
                              change: [
                                function($event) {
                                  var $$a = _vm.filterReplyReqs,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = "unhandled",
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.filterReplyReqs = $$a.concat([
                                          $$v
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.filterReplyReqs = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.filterReplyReqs = $$c
                                  }
                                },
                                function($event) {
                                  return _vm.onFilterChange("reply-reqs")
                                }
                              ]
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            { attrs: { for: "unhandled-reply-reqs" } },
                            [
                              _vm._v(
                                "\n                Pending TA reply requests\n              "
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", [
                        _c("label", { attrs: { for: "min-words" } }, [
                          _vm._v(
                            "\n                Min. # of words\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.minWords,
                              expression: "minWords"
                            }
                          ],
                          attrs: {
                            type: "number",
                            id: "min-words",
                            placeholder: "0",
                            min: "0"
                          },
                          domProps: { value: _vm.minWords },
                          on: {
                            keypress: function(event) {
                              return _vm.validateNumber(event)
                            },
                            change: function($event) {
                              return _vm.onFilterChange("min-words")
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.minWords = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("label", { attrs: { for: "max-words" } }, [
                          _vm._v(
                            "\n                Max. # of words\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.maxWords,
                              expression: "maxWords"
                            }
                          ],
                          attrs: {
                            type: "number",
                            id: "max-words",
                            placeholder: "n/a",
                            min: "0"
                          },
                          domProps: { value: _vm.maxWords },
                          on: {
                            keypress: function(event) {
                              return _vm.validateNumber(event)
                            },
                            change: function($event) {
                              return _vm.onFilterChange("max-words")
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.maxWords = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("label", { attrs: { for: "min-hashtags" } }, [
                          _vm._v(
                            "\n                Min. # of hashtags\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.minHashtags,
                              expression: "minHashtags"
                            }
                          ],
                          attrs: {
                            type: "number",
                            id: "min-hashtags",
                            placeholder: "0",
                            min: "0"
                          },
                          domProps: { value: _vm.minHashtags },
                          on: {
                            keypress: function(event) {
                              return _vm.validateNumber(event)
                            },
                            change: function($event) {
                              return _vm.onFilterChange("min-hashtags")
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.minHashtags = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("label", { attrs: { for: "max-hashtags" } }, [
                          _vm._v(
                            "\n                Max. # of hashtags\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.maxHashtags,
                              expression: "maxHashtags"
                            }
                          ],
                          attrs: {
                            type: "number",
                            id: "max-hashtags",
                            placeholder: "n/a",
                            min: "0"
                          },
                          domProps: { value: _vm.maxHashtags },
                          on: {
                            keypress: function(event) {
                              return _vm.validateNumber(event)
                            },
                            change: function($event) {
                              return _vm.onFilterChange("max-hashtags")
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.maxHashtags = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("label", { attrs: { for: "min-replies" } }, [
                          _vm._v(
                            "\n                Min. # of replies\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.minReplies,
                              expression: "minReplies"
                            }
                          ],
                          attrs: {
                            type: "number",
                            id: "min-replies",
                            placeholder: "0",
                            min: "0"
                          },
                          domProps: { value: _vm.minReplies },
                          on: {
                            keypress: function(event) {
                              return _vm.validateNumber(event)
                            },
                            change: function($event) {
                              return _vm.onFilterChange("min-replies")
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.minReplies = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("label", { attrs: { for: "min-rep-reqs" } }, [
                          _vm._v(
                            "\n                Min. # of reply requests\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.minReplyReqs,
                              expression: "minReplyReqs"
                            }
                          ],
                          attrs: {
                            type: "number",
                            id: "min-reply-reqs",
                            placeholder: "0",
                            min: "0"
                          },
                          domProps: { value: _vm.minReplyReqs },
                          on: {
                            keypress: function(event) {
                              return _vm.validateNumber(event)
                            },
                            change: function($event) {
                              return _vm.onFilterChange("min-reply-reqs")
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.minReplyReqs = $event.target.value
                            }
                          }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _c("label", { attrs: { for: "min-upvotes" } }, [
                          _vm._v(
                            "\n                Min. # of upvotes\n              "
                          )
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.minUpvotes,
                              expression: "minUpvotes"
                            }
                          ],
                          attrs: {
                            type: "number",
                            id: "min-upvotes",
                            placeholder: "0",
                            min: "0"
                          },
                          domProps: { value: _vm.minUpvotes },
                          on: {
                            keypress: function(event) {
                              return _vm.validateNumber(event)
                            },
                            change: function($event) {
                              return _vm.onFilterChange("min-upvotes")
                            },
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.minUpvotes = $event.target.value
                            }
                          }
                        })
                      ])
                    ])
                  : _vm._e()
              ])
            ])
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }