var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "thread-row", style: _vm.styleRow }, [
      _c("div", { staticClass: "header" }, [
        _c(
          "span",
          { staticClass: "author" },
          [
            !_vm.authorIsMe && !_vm.isAnonymous && _vm.isFollowing()
              ? _c("font-awesome-icon", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: "unfollow author",
                      expression: "'unfollow author'"
                    }
                  ],
                  staticClass: "unfollow-icon",
                  attrs: { icon: "user-check" },
                  on: {
                    click: function($event) {
                      return _vm.unfollowAuthor(_vm.comment)
                    }
                  }
                })
              : !_vm.authorIsMe && !_vm.isAnonymous
              ? _c("font-awesome-icon", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: "follow author",
                      expression: "'follow author'"
                    }
                  ],
                  staticClass: "follow-icon",
                  attrs: { icon: "user-plus" },
                  on: {
                    click: function($event) {
                      return _vm.followAuthor(_vm.comment)
                    }
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.comment.instructor && !_vm.comment.isAnonymous()
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "Instructor",
                        expression: "'Instructor'"
                      }
                    ],
                    staticClass: "instr-icon"
                  },
                  [_vm._v("\n                    i\n                ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.comment.ta && !_vm.comment.isAnonymous()
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "TA",
                        expression: "'TA'"
                      }
                    ],
                    staticClass: "ta-icon"
                  },
                  [_vm._v("\n                    TA\n                ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isShowAnonymousAuthorName
              ? _c(
                  "span",
                  {
                    staticClass: "author-info",
                    on: {
                      mousedown: function($event) {
                        return _vm.toggleAnonymousAuthor()
                      }
                    }
                  },
                  [
                    _vm.isHideAnonymousAuthor
                      ? _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: "eye" }
                        })
                      : _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: "eye-slash" }
                        })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("b", [_vm._v(_vm._s(_vm.authorName))]),
            _vm._v(
              _vm._s(_vm.comment.author === _vm.me.id ? " (me)" : "") +
                "\n            "
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.timeFull,
                expression: "timeFull"
              }
            ],
            staticClass: "timestamp"
          },
          [_vm._v(_vm._s(_vm.timeString))]
        ),
        _vm._v(" "),
        _vm.isShowVisibility
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.visibilityText,
                    expression: "visibilityText"
                  }
                ],
                staticClass: "visibility"
              },
              [
                _vm._v(" | "),
                _c("span", { class: _vm.comment.visibility }, [
                  _vm._v(_vm._s(_vm.comment.visibility))
                ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "options" },
          [
            _vm.comment.endorsed
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value:
                          "This comment has been endorsed by an instructor",
                        expression:
                          "'This comment has been endorsed by an instructor'"
                      }
                    ],
                    staticClass: "icon-wrapper instr-endorsed"
                  },
                  [_vm._v("\n                    i\n                ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.comment.taEndorsed
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: "This comment has been endorsed by a TA",
                        expression: "'This comment has been endorsed by a TA'"
                      }
                    ],
                    staticClass: "icon-wrapper ta-endorsed"
                  },
                  [_vm._v("\n                    TA\n                ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: _vm.comment.bookmarked
                      ? "remove bookmark"
                      : "bookmark",
                    expression:
                      "comment.bookmarked ? 'remove bookmark' : 'bookmark'"
                  }
                ],
                staticClass: "bookmark",
                on: {
                  click: function($event) {
                    return _vm.toggleBookmark(_vm.comment)
                  }
                }
              },
              [
                _vm.comment.bookmarked
                  ? _c("font-awesome-icon", {
                      staticClass: "fas icon",
                      attrs: { icon: ["fas", "bookmark"] }
                    })
                  : _c("font-awesome-icon", {
                      staticClass: "far icon",
                      attrs: { icon: ["far", "bookmark"] }
                    })
              ],
              1
            ),
            _vm._v(" "),
            _vm.firstComment || _vm.commentEditable
              ? _c(
                  "v-popover",
                  {
                    staticClass: "overflow-menu",
                    attrs: {
                      popoverClass: "thread-overflow-wrapper",
                      container: "#nb-app-wrapper",
                      boundariesElement: "#nb-app-wrapper",
                      offset: "0",
                      placement: "bottom-end",
                      open: _vm.showOverflow
                    },
                    on: { hide: _vm.onHideOverflow }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "tooltip-target overflow-icon",
                        on: {
                          click: function($event) {
                            _vm.showOverflow = true
                          }
                        }
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "ellipsis-v" }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("template", { slot: "popover" }, [
                      _c("div", { staticClass: "overflow-options" }, [
                        _vm.firstComment
                          ? _c(
                              "div",
                              {
                                staticClass: "overflow-option",
                                on: {
                                  click: function($event) {
                                    return _vm.copyLink(_vm.comment)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        Copy link\n                        "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.commentEditable
                          ? _c(
                              "div",
                              {
                                staticClass: "overflow-option",
                                on: {
                                  click: function($event) {
                                    return _vm.editComment(_vm.comment)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        Edit\n                        "
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.commentEditable
                          ? _c(
                              "div",
                              {
                                staticClass: "overflow-option",
                                on: {
                                  click: function($event) {
                                    return _vm.deleteComment(_vm.comment)
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                        Delete\n                        "
                                )
                              ]
                            )
                          : _vm._e()
                      ])
                    ])
                  ],
                  2
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.comment.type === "audio"
        ? _c("div", { staticClass: "media" }, [
            _c("audio", {
              attrs: { id: _vm.comment.id, src: _vm.mediaPath, controls: "" }
            })
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", {
        staticClass: "body",
        domProps: { innerHTML: _vm._s(_vm.comment.html) }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "footer" }, [
        _c(
          "span",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "reply",
                expression: "'reply'"
              }
            ],
            on: {
              click: function($event) {
                return _vm.draftReply(_vm.comment)
              }
            }
          },
          [
            _c("font-awesome-icon", {
              staticClass: "icon",
              attrs: { icon: "reply" }
            }),
            _vm._v(
              "\n            " +
                _vm._s(_vm.comment.countAllReplies()) +
                "\n            "
            )
          ],
          1
        ),
        _vm._v("\n             · \n            "),
        _c(
          "span",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.currentConfigs.isExpClass
                  ? _vm.comment.upvotedByMe
                    ? "undo looking for classmates' perspectives"
                    : "looking for classmates' perspectives"
                  : _vm.comment.upvotedByMe
                  ? "undo upvote"
                  : "upvote",
                expression:
                  "currentConfigs.isExpClass ? (comment.upvotedByMe ? `undo looking for classmates' perspectives` : `looking for classmates' perspectives`) : (comment.upvotedByMe ? 'undo upvote' : 'upvote')"
              }
            ],
            on: {
              click: function($event) {
                return _vm.toggleUpvote(_vm.comment)
              }
            }
          },
          [
            _vm.currentConfigs.isExpClass
              ? _c("span", { staticClass: "icon", style: _vm.styleUpvote }, [
                  _vm._v("👁️‍🗨️")
                ])
              : _c("font-awesome-icon", {
                  staticClass: "icon",
                  style: _vm.styleUpvote,
                  attrs: { icon: "thumbs-up" }
                }),
            _vm._v(
              "\n                " +
                _vm._s(_vm.comment.upvoteCount) +
                "\n            "
            )
          ],
          1
        ),
        _vm._v("\n             · \n            "),
        _c(
          "span",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.comment.replyRequestedByMe
                  ? "undo request"
                  : "request reply",
                expression:
                  "comment.replyRequestedByMe ? 'undo request' : 'request reply'"
              }
            ],
            on: {
              click: function($event) {
                return _vm.toggleReplyRequest(_vm.comment)
              }
            }
          },
          [
            _c("font-awesome-icon", {
              staticClass: "icon",
              style: _vm.styleQuestion,
              attrs: { icon: "question" }
            }),
            _vm._v(
              "\n            " +
                _vm._s(_vm.comment.replyRequestCount) +
                "\n            "
            )
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _vm.comment.children.length
      ? _c(
          "div",
          { staticClass: "thread-block" },
          _vm._l(_vm.comment.children, function(child, index) {
            return _c("thread-comment", {
              key: child.id,
              attrs: {
                comment: child,
                me: _vm.me,
                replyToComment: _vm.replyToComment,
                activeClass: _vm.activeClass,
                "thread-view-initiator": _vm.threadViewInitiator,
                last: index === _vm.comment.children.length - 1,
                myfollowing: _vm.myfollowing,
                "current-configs": _vm.currentConfigs
              },
              on: {
                "log-nb": _vm.onLogNb,
                "edit-comment": _vm.editComment,
                "delete-comment": _vm.deleteComment,
                "draft-reply": _vm.draftReply,
                "toggle-upvote": _vm.toggleUpvote,
                "toggle-reply-request": _vm.toggleReplyRequest,
                "submit-small-comment": _vm.submitSmallComment,
                "follow-author": _vm.followAuthor,
                "unfollow-author": _vm.unfollowAuthor
              }
            })
          }),
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.currentConfigs.isShowQuickEditor && _vm.last && _vm.comment.parent
      ? _c("div", { staticClass: "thread-row smallComment" }, [
          _c("div", { staticClass: "smallCommentHeader" }, [
            _c("span", { staticClass: "author" }, [
              _vm.me.role === "instructor"
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: "Instructor",
                          expression: "'Instructor'"
                        }
                      ],
                      staticClass: "instr-icon"
                    },
                    [_vm._v("\n                    i\n                ")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("b", [
                _vm._v(
                  _vm._s(_vm.me.first_name) + " " + _vm._s(_vm.me.last_name)
                )
              ]),
              _vm._v(_vm._s(" (me)") + "\n            ")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "smallCommentInput" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.smallComment,
                  expression: "smallComment"
                }
              ],
              staticClass: "smallCommentText",
              attrs: { type: "text", placeholder: "Write a comment..." },
              domProps: { value: _vm.smallComment },
              on: {
                keyup: _vm.checkSubmittedSmallComment,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.smallComment = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "Open rich text editor",
                    expression: "'Open rich text editor'"
                  }
                ],
                staticClass: "smallCommentButton",
                on: {
                  click: function($event) {
                    return _vm.draftReply(_vm.comment.parent)
                  }
                }
              },
              [_c("b-icon-arrows-angle-expand")],
              1
            )
          ]),
          _vm._v(" "),
          _c("br"),
          _c("br")
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }