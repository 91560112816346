var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "login-form",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.login($event)
        }
      }
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "group" }, [
        _c("label", { attrs: { for: "login-username" } }, [
          _vm._v("Username:")
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.username,
              expression: "username",
              modifiers: { trim: true }
            }
          ],
          attrs: { id: "login-username", type: "text" },
          domProps: { value: _vm.username },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.username = $event.target.value.trim()
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "group" }, [
        _c("label", { attrs: { for: "login-password" } }, [
          _vm._v("Password:")
        ]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.trim",
              value: _vm.password,
              expression: "password",
              modifiers: { trim: true }
            }
          ],
          attrs: { id: "login-password", type: "password" },
          domProps: { value: _vm.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value.trim()
            },
            blur: function($event) {
              return _vm.$forceUpdate()
            }
          }
        })
      ]),
      _vm._v(" "),
      _vm.message
        ? _c("div", { staticClass: "message" }, [_vm._v(_vm._s(_vm.message))])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "buttons" }, [
        _c(
          "button",
          {
            staticClass: "submit",
            attrs: { type: "submit", disabled: _vm.submitDisabled }
          },
          [_vm._v("\n      Submit\n    ")]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "title" }, [
      _c("img", {
        attrs: { src: "https://nb.mit.edu/res/nb-logo.png", height: "60" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }