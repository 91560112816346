var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showSyncFeatures && _vm.draggableNotificationsOpened
    ? _c(
        "vue-draggable-resizable",
        {
          attrs: {
            w: 330,
            h: 525,
            z: 30000000,
            x: -500,
            y: 250,
            handles: ["tl", "tr", "br", "bl"],
            "min-height": 300,
            "min-width": 325,
            parent: false
          }
        },
        [
          _c("notification-sidebar-view", {
            attrs: {
              user: _vm.user,
              activeClass: _vm.activeClass,
              notifications: _vm.notificationThreads,
              "total-count": _vm.notificationThreads.length,
              "thread-selected": _vm.threadSelected,
              "notification-selected": _vm.notificationSelected,
              "threads-hovered": _vm.threadsHovered,
              "show-highlights": _vm.showHighlights,
              notificationsMuted: _vm.notificationsMuted
            },
            on: {
              "toggle-highlights": _vm.onToggleHighlights,
              "select-notification": _vm.onSelectNotification,
              "hover-thread": _vm.onHoverThread,
              "unhover-thread": _vm.onUnhoverThread,
              "toggle-mute-notifications": _vm.onToggleMuteNotifications,
              "dock-draggable-notifications": function($event) {
                return _vm.$emit("dock-draggable-notifications")
              },
              "close-draggable-notications": function($event) {
                return _vm.$emit("close-draggable-notications")
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }