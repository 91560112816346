var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nb-nav-bar" }, [
    _c(
      "a",
      {
        staticClass: "logo",
        attrs: { target: "_blank", href: "http://nb2.csail.mit.edu/" }
      },
      [_vm._v("nb")]
    ),
    _vm._v(" "),
    _c("span", [
      _c("em", [_vm._v("Welcome")]),
      _vm._v(", "),
      _c("strong", [_vm._v(_vm._s(_vm.me.name.first + " " + _vm.me.name.last))])
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "a",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: "Report Bug",
                expression: "'Report Bug'"
              }
            ],
            staticClass: "bug-icon",
            attrs: {
              href: "https://forms.gle/6YERC3jSu1W1zUzS8",
              target: "_blank"
            }
          },
          [
            _c("font-awesome-icon", {
              staticClass: "icon",
              attrs: { icon: "bug" }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "v-popover",
          {
            staticClass: "overflow-menu",
            attrs: {
              popoverClass: "nav-overflow-wrapper",
              container: "#nb-app-wrapper",
              offset: "0",
              placement: "bottom-end",
              open: _vm.showOverflow
            },
            on: { hide: _vm.onHideOverflow }
          },
          [
            _c(
              "span",
              {
                staticClass: "tooltip-target overflow-icon",
                on: { click: _vm.toggleOverflow }
              },
              [
                _c("font-awesome-icon", {
                  staticClass: "icon",
                  attrs: { icon: "bars" }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("template", { slot: "popover" }, [
              _c("div", { staticClass: "overflow-options" }, [
                _c(
                  "div",
                  {
                    staticClass: "overflow-option",
                    on: {
                      click: function($event) {
                        return _vm.$emit("logout")
                      }
                    }
                  },
                  [_vm._v("\n            Logout\n          ")]
                )
              ])
            ])
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }