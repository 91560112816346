var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "nb-highlights",
      on: { "unselect-thread": _vm.onUnselectThread }
    },
    [
      _vm._l(_vm.threads, function(thread) {
        return _c("nb-highlight", {
          key: thread,
          attrs: {
            thread: thread,
            "thread-selected": _vm.threadSelected,
            "threads-hovered": _vm.threadsHovered,
            "show-highlights": _vm.showHighlights,
            "show-spotlights": _vm.showSpotlights,
            user: _vm.user,
            activeClass: _vm.activeClass,
            "current-configs": _vm.currentConfigs,
            "show-sync-features": _vm.showSyncFeatures
          },
          on: {
            "log-nb": _vm.onLogNb,
            "select-thread": _vm.onSelectThread,
            "hover-thread": function($event) {
              return _vm.$emit("hover-thread", thread)
            },
            "unhover-thread": function($event) {
              return _vm.$emit("unhover-thread", thread)
            },
            "new-recent-thread": function($event) {
              return _vm.$emit("new-recent-thread", thread)
            }
          }
        })
      }),
      _vm._v(" "),
      _vm._l(_vm.threadsHidden, function(thread) {
        return _c("nb-highlight", {
          key: thread,
          attrs: {
            thread: thread,
            "thread-selected": _vm.threadSelected,
            "threads-hovered": _vm.threadsHovered,
            "show-highlights": _vm.showHighlights,
            "show-spotlights": _vm.showSpotlights,
            user: _vm.user,
            activeClass: _vm.activeClass,
            "current-configs": _vm.currentConfigs,
            "show-sync-features": _vm.showSyncFeatures,
            "is-hidden": true
          },
          on: {
            "log-nb": _vm.onLogNb,
            "select-thread": _vm.onSelectThread,
            "hover-thread": function($event) {
              return _vm.$emit("hover-thread", thread)
            },
            "unhover-thread": function($event) {
              return _vm.$emit("unhover-thread", thread)
            },
            "new-recent-thread": function($event) {
              return _vm.$emit("new-recent-thread", thread)
            }
          }
        })
      }),
      _vm._v(" "),
      _vm.draftRange
        ? _c("nb-highlight", { attrs: { range: _vm.draftRange } })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }