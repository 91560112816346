var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      key: _vm.thread.id,
      staticClass: "list-row",
      style: _vm.rowStyle,
      attrs: { id: _vm.thread.id },
      on: {
        mouseenter: function($event) {
          return _vm.$emit("hover-thread", _vm.thread)
        },
        mouseleave: function($event) {
          return _vm.$emit("unhover-thread", _vm.thread)
        },
        click: function($event) {
          return _vm.onClick()
        }
      }
    },
    [
      _c("div", { staticClass: "top-line" }, [
        _vm.thread.text !== ""
          ? _c("span", { style: _vm.textStyle }, [
              _vm._v("\n            " + _vm._s(_vm.thread.text) + "\n        ")
            ])
          : _c("span", { style: _vm.textStyle }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.thread.type) +
                  " by " +
                  _vm._s(_vm.authorName) +
                  "\n        "
              )
            ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bottom-line" }, [
        _c("div", { staticClass: "flags" }, [
          _vm.currentConfigs.isShowNumberOfReplies
            ? _c(
                "div",
                {
                  staticClass: "icon-wrapper counter",
                  style: _vm.counterStyle
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.thread.countAllReplies() + 1) +
                      "\n            "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isSpotlightEnabled
            ? _c("div", [
                _vm.isSpotlighted
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Spotlight comment",
                            expression: "'Spotlight comment'"
                          }
                        ],
                        staticClass: "icon-wrapper inno"
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              (_vm.spotlight.type === "IN" && "~") ||
                                (_vm.spotlight.type === "ABOVE" && "↑") ||
                                (_vm.spotlight.type === "BELLOW" && "↓") ||
                                (_vm.spotlight.type === "LEFT" && "←") ||
                                (_vm.spotlight.type === "RIGHT" && "→") ||
                                (_vm.spotlight.type === "MARGIN" && "Ɱ") ||
                                (_vm.spotlight.type === "EM" && "❖")
                            ) +
                            "\n                "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.currentConfigs.isShowIndicatorForInstructorComment
            ? _c("div", [
                _vm.thread.hasInstructorPost()
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Comment by an instructor",
                            expression: "'Comment by an instructor'"
                          }
                        ],
                        staticClass: "icon-wrapper instr"
                      },
                      [_vm._v("\n                    i\n                ")]
                    )
                  : _vm.thread.isEndorsed()
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Endorsed by an instructor",
                            expression: "'Endorsed by an instructor'"
                          }
                        ],
                        staticClass: "icon-wrapper instr-endorsed"
                      },
                      [_vm._v("\n                    i\n                ")]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.currentConfigs.isShowIndicatorForTAComment
            ? _c("div", [
                _vm.thread.hasTAPost()
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Comment by a TA",
                            expression: "'Comment by a TA'"
                          }
                        ],
                        staticClass: "icon-wrapper ta"
                      },
                      [_vm._v("\n                    TA\n                ")]
                    )
                  : _vm.thread.isTAEndorsed()
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Endorsed by a TA",
                            expression: "'Endorsed by a TA'"
                          }
                        ],
                        staticClass: "icon-wrapper ta-endorsed"
                      },
                      [_vm._v("\n                    TA\n                ")]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.currentConfigs.isShowIndicatorForFollowComment
            ? _c("div", [
                _vm.isFollowing
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "Comment by an author you follow",
                            expression: "'Comment by an author you follow'"
                          }
                        ],
                        staticClass: "icon-wrapper follow"
                      },
                      [
                        _c("font-awesome-icon", {
                          attrs: { icon: "user-check" }
                        })
                      ],
                      1
                    )
                  : _c("div", { staticClass: "placeholder follow" })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.currentConfigs.isShowIndicatorForQuestionedThread
            ? _c("div", [
                _vm.thread.hasReplyRequests()
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: "This comment has a reply request",
                            expression: "'This comment has a reply request'"
                          }
                        ],
                        staticClass: "icon-wrapper question"
                      },
                      [
                        _c("font-awesome-icon", { attrs: { icon: "question" } })
                      ],
                      1
                    )
                  : _c("div", { staticClass: "placeholder question" })
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.currentConfigs.isShowIndicatorForNotifiedThread
            ? _c("div", [
                this.showSyncFeatures &&
                _vm.thread.associatedNotification !== null
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value:
                              "This comment has an associated notification",
                            expression:
                              "'This comment has an associated notification'"
                          }
                        ],
                        staticClass: "icon-wrapper notification"
                      },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: "bell" }
                        })
                      ],
                      1
                    )
                  : _c("div", { staticClass: "placeholder notification" })
              ])
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "time", style: _vm.timeStyle }, [
          _c("b", [_vm._v(_vm._s(_vm.authorName))]),
          _vm._v(" "),
          _vm.currentConfigs.isShowIndicatorForTime
            ? _c("span", [_vm._v("@ " + _vm._s(_vm.ago))])
            : _vm._e()
        ]),
        _vm._v(" "),
        _vm.showSyncFeatures
          ? _c("div", { staticClass: "typing" }, [
              _c(
                "span",
                _vm._l(_vm.thread.usersTyping, function(user) {
                  return _c("avatar", {
                    key: user,
                    attrs: { fullname: user, size: 14 }
                  })
                }),
                1
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }