var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "nb-sidebar",
      class: {
        draggable: _vm.isDraggable || _vm.editor.visible,
        "animatable-width": !_vm.isDragging
      },
      style: _vm.style,
      attrs: { id: "nb-sidebar" },
      on: { mousedown: _vm.mouseDown }
    },
    [
      _c("nav-bar", {
        attrs: { me: _vm.user },
        on: {
          logout: function($event) {
            return _vm.$emit("logout")
          }
        }
      }),
      _vm._v(" "),
      _vm.myClasses.length > 1
        ? _c(
            "div",
            { staticClass: "nb-menu" },
            [
              _c("nb-menu", {
                attrs: {
                  myClasses: _vm.myClasses,
                  activeClass: _vm.activeClass
                },
                on: { "switch-class": _vm.onSwitchClass }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.syncConfig
        ? _c("nb-online", {
            attrs: {
              "online-users": _vm.onlineUsers,
              "show-sync-features": _vm.showSyncFeatures,
              "nb-menu-showing": _vm.myClasses.length > 1,
              "number-notifications-unseen": _vm.numberNotificationsUnseen,
              "notifications-muted": _vm.notificationsMuted
            },
            on: {
              "show-sync-features": _vm.onShowSyncFeatures,
              "toggle-mute-notifications": _vm.onToggleMuteNotifications,
              "open-draggable-notifications": _vm.onOpenDraggableNotifications,
              "open-sidebar-notifications": _vm.onOpenSidebarNotifications
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showSyncFeatures && _vm.sidebarNotificationsOpened
        ? _c("notification-view", {
            attrs: {
              notifications: _vm.notificationThreads,
              "total-count": _vm.notificationThreads.length,
              "thread-selected": _vm.threadSelected,
              "notification-selected": _vm.notificationSelected,
              "threads-hovered": _vm.threadsHovered,
              "show-highlights": _vm.showHighlights,
              "still-gathering-threads": _vm.stillGatheringThreads,
              "draggable-notifications-opened":
                _vm.draggableNotificationsOpened,
              "notifications-muted": _vm.notificationsMuted,
              activeClass: _vm.activeClass,
              user: _vm.user
            },
            on: {
              "toggle-highlights": _vm.onToggleHighlights,
              "select-notification": _vm.onSelectNotification,
              "hover-thread": _vm.onHoverThread,
              "unhover-thread": _vm.onUnhoverThread,
              "toggle-mute-notifications": _vm.onToggleMuteNotifications,
              "undock-draggable-notifications":
                _vm.onUndockDraggableNotifications,
              "close-sidebar-notifications": _vm.onCloseSidebarNotifications
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("filter-view", {
        attrs: {
          me: _vm.user,
          users: _vm.sortedUsers,
          hashtags: _vm.sortedHashtags,
          "sync-config": _vm.syncConfig,
          "filter-visible": _vm.filterVisible,
          "current-configs": _vm.currentConfigs,
          filter: _vm.filter
        },
        on: {
          "search-option": _vm.onSearchOption,
          "search-text": _vm.onSearchText,
          "filter-bookmarks": _vm.onFilterBookmarks,
          "filter-hashtags": _vm.onFilterHashtags,
          "filter-user-tags": _vm.onFilterUserTags,
          "filter-comments": _vm.onFilterComments,
          "filter-reply-reqs": _vm.onFilterReplyReqs,
          "filter-upvotes": _vm.onFilterUpvotes,
          "min-words": _vm.onMinWords,
          "max-words": _vm.onMaxWords,
          "min-hashtags": _vm.onMinHashtags,
          "max-hashtags": _vm.onMaxHashtags,
          "max-threads": _vm.onMaxThreads,
          "min-replies": _vm.onMinReplies,
          "min-reply-reqs": _vm.onMinReplyReqs,
          "min-upvotes": _vm.onMinUpvotes,
          "toggle-filters": _vm.onToggleFilters,
          "filter-hide": _vm.onFilterHide
        }
      }),
      _vm._v(" "),
      _c("list-view", {
        attrs: {
          threads: _vm.threads,
          "total-count": _vm.totalThreads,
          "thread-selected": _vm.threadSelected,
          "threads-hovered": _vm.threadsHovered,
          "show-highlights": _vm.showHighlights,
          "show-spotlights": _vm.showSpotlights,
          "still-gathering-threads": _vm.stillGatheringThreads,
          "current-configs": _vm.currentConfigs,
          activeClass: _vm.activeClass,
          user: _vm.user,
          "show-sync-features": _vm.showSyncFeatures,
          myfollowing: _vm.myfollowing,
          filter: _vm.filter,
          "is-editor-visible": _vm.editor.visible
        },
        on: {
          "handle-redraw-highlights": _vm.onHandleRedrawHighlights,
          "log-nb": _vm.onLogNb,
          "toggle-highlights": _vm.onToggleHighlights,
          "toggle-spotlights": _vm.onToggleSpotlights,
          "select-thread": _vm.onSelectThread,
          "hover-thread": _vm.onHoverThread,
          "unhover-thread": _vm.onUnhoverThread,
          "filter-show": _vm.onFilterShow
        }
      }),
      _vm._v(" "),
      _vm.threadSelected
        ? _c("thread-view", {
            attrs: {
              thread: _vm.threadSelected,
              me: _vm.user,
              replyToComment: _vm.replyToComment,
              "current-configs": _vm.currentConfigs,
              activeClass: _vm.activeClass,
              "thread-view-initiator": _vm.threadViewInitiator,
              myfollowing: _vm.myfollowing
            },
            on: {
              "log-nb": _vm.onLogNb,
              "edit-comment": _vm.onEditComment,
              "delete-comment": _vm.onDeleteComment,
              "draft-reply": _vm.onDraftReply,
              "submit-small-comment": _vm.onSubmitSmallComment,
              "prev-comment": _vm.onPrevComment,
              "next-comment": _vm.onNextComment,
              "follow-author": _vm.followAuthor,
              "unfollow-author": _vm.unfollowAuthor
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("editor-view", {
        key: _vm.editor.key,
        attrs: {
          author: _vm.user,
          visible: _vm.editor.visible,
          header: _vm.editor.header,
          "initial-content": _vm.editor.initialContent,
          "initial-visibility": _vm.editor.initialSettings.visibility,
          "initial-anonymity": _vm.editor.initialSettings.anonymity,
          "initial-reply-request": _vm.editor.initialSettings.replyRequested,
          "initial-upvoted-by-me": _vm.editor.initialSettings.upvotedByMe,
          users: _vm.sortedUsers,
          hashtags: _vm.sortedHashtags,
          "is-submitting": _vm.editor.isSubmitting,
          "current-configs": _vm.currentConfigs
        },
        on: {
          "editor-empty": _vm.onEditorEmpty,
          "submit-comment": _vm.onSubmitComment,
          "cancel-comment": _vm.onCancelComment,
          "thread-typing": _vm.onThreadTyping,
          "thread-stop-typing": _vm.onThreadStopTyping
        }
      }),
      _vm._v(" "),
      _c("notifications", {
        attrs: { position: "bottom right", group: "annotation" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }