var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      attrs: { tabindex: "0" },
      on: { keyup: _vm.handleKeyUp, keypress: _vm.handleKeyPress }
    },
    [
      _c("quill", {
        attrs: { output: "html", config: _vm.config },
        on: {
          input: _vm.onTextChange,
          "selection-change": _vm.onSelectionChange
        },
        model: {
          value: _vm.content,
          callback: function($$v) {
            _vm.content = $$v
          },
          expression: "content"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }