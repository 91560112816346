var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "nb-notification-sidebar list-view" }, [
    _c(
      "header",
      {
        staticClass: "card-header",
        attrs: { id: "draggable-notification-card-header" }
      },
      [
        _c("p", { staticClass: "card-header-title" }, [
          _vm._v(
            _vm._s(_vm.title) +
              " (" +
              _vm._s(_vm.numberUnseen) +
              " / " +
              _vm._s(_vm.totalCount) +
              " unread)"
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "icons-left-parent" }, [
          _c(
            "span",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.notificationsMuted
                    ? "Click to unmute notifications"
                    : "Click to mute notifications",
                  expression:
                    "notificationsMuted ? 'Click to unmute notifications' : 'Click to mute notifications'"
                }
              ],
              staticClass: "icons-left",
              on: { click: _vm.toggleMute }
            },
            [
              _vm.notificationsMuted
                ? _c("font-awesome-icon", {
                    staticClass: "icon",
                    attrs: { icon: "bell-slash" }
                  })
                : _c("font-awesome-icon", {
                    staticClass: "icon",
                    attrs: { icon: "bell" }
                  })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Click to dock notifications into sidebar",
                  expression: "'Click to dock notifications into sidebar'"
                }
              ],
              staticClass: "icons-left",
              on: {
                click: function($event) {
                  return _vm.$emit("dock-draggable-notifications")
                }
              }
            },
            [
              _c("font-awesome-icon", {
                staticClass: "icon",
                attrs: { icon: "clone" }
              })
            ],
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "notification-table" },
      [
        _vm._l(_vm.onlineNotifications, function(notification, index) {
          return _c("notification-sidebar-row", {
            key:
              _vm.onlineNotifications[
                _vm.onlineNotifications.length - 1 - index
              ],
            attrs: {
              notification:
                _vm.onlineNotifications[
                  _vm.onlineNotifications.length - 1 - index
                ],
              "thread-selected": _vm.threadSelected,
              "notification-selected": _vm.notificationSelected,
              "threads-hovered": _vm.threadsHovered,
              user: _vm.user,
              "active-class": _vm.activeClass
            },
            on: {
              "select-notification": _vm.onSelectNotification,
              "hover-thread": _vm.onHoverNotification,
              "unhover-thread": _vm.onUnhoverNotification
            }
          })
        }),
        _vm._v(" "),
        _vm.offlineNotifications.length > 0
          ? _c("h4", { attrs: { id: "olderNotificationHeading" } }, [
              _c(
                "span",
                { attrs: { id: "olderNotificationSpanGray" } },
                [
                  _c("font-awesome-icon", { attrs: { icon: "chevron-down" } }),
                  _vm._v("\n          Older Notifications\n          "),
                  _c("font-awesome-icon", { attrs: { icon: "chevron-down" } })
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm._l(_vm.offlineNotifications, function(notification, index) {
          return _c("notification-sidebar-row", {
            key:
              _vm.offlineNotifications[
                _vm.offlineNotifications.length - 1 - index
              ],
            attrs: {
              notification:
                _vm.offlineNotifications[
                  _vm.offlineNotifications.length - 1 - index
                ],
              "thread-selected": _vm.threadSelected,
              "notification-selected": _vm.notificationSelected,
              "threads-hovered": _vm.threadsHovered,
              user: _vm.user,
              "active-class": _vm.activeClass
            },
            on: {
              "select-notification": _vm.onSelectNotification,
              "hover-thread": _vm.onHoverNotification,
              "unhover-thread": _vm.onUnhoverNotification
            }
          })
        })
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "notification-close-button",
        on: {
          click: function($event) {
            return _vm.$emit("close-draggable-notications")
          }
        }
      },
      [_vm._v("X")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }