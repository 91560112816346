var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search" }, [
    _c("div", { staticClass: "dropdown" }, [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchBy,
              expression: "searchBy"
            }
          ],
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.searchBy = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        [
          _c("option", { attrs: { value: "text" } }, [_vm._v("Text")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "author" } }, [_vm._v("Author")])
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "text" },
      [
        _c("text-editor", {
          attrs: {
            placeholder: _vm.placeholder,
            users: _vm.users,
            hashtags: _vm.hashtags
          },
          on: { "text-change": _vm.onTextChange }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }