var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "online-control" }, [
    _c("div", { staticClass: "online-control-header" }, [
      _vm._v("\n        CLASS\n    ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "online-control-controls" }, [
      _c(
        "span",
        {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.showSyncFeatures
                ? "disable notifications"
                : "enable notifications",
              expression:
                "showSyncFeatures ? 'disable notifications' : 'enable notifications'"
            }
          ]
        },
        [
          _c("font-awesome-icon", {
            staticClass: "icon bell",
            attrs: { icon: "bell" }
          }),
          _vm._v(" "),
          _c(
            "span",
            { on: { click: _vm.onShowSyncFeaturesChange } },
            [
              _vm.showSyncFeatures
                ? _c("font-awesome-icon", {
                    staticClass: "icon toggle-on",
                    attrs: { icon: "toggle-on" }
                  })
                : _c("font-awesome-icon", {
                    staticClass: "icon",
                    attrs: { icon: "toggle-off" }
                  })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.showSyncFeatures
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Users online",
                  expression: "'Users online'"
                }
              ]
            },
            [
              _c("font-awesome-icon", {
                staticClass: "icon",
                attrs: { icon: "users" }
              }),
              _vm._v(" "),
              _c("span", { staticClass: "count-number" }, [
                _vm._v(_vm._s(_vm.onlineUsersCount))
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSyncFeatures
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Instructors online",
                  expression: "'Instructors online'"
                }
              ]
            },
            [
              _c("span", { staticClass: "online-instructors" }, [_vm._v("i")]),
              _vm._v(" "),
              _c("span", { staticClass: "count-number" }, [
                _vm._v(_vm._s(_vm.onlineInstructorsCount))
              ])
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showSyncFeatures
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: "Instructors online",
                  expression: "'Instructors online'"
                }
              ]
            },
            [
              _c(
                "v-popover",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: "Open notifications",
                      expression: "'Open notifications'"
                    }
                  ],
                  staticClass: "overflow-menu",
                  attrs: {
                    popoverClass: "thread-overflow-wrapper",
                    container: "#nb-app-wrapper",
                    boundariesElement: "#nb-app-wrapper",
                    offset: "0",
                    placement: "bottom-end",
                    open: _vm.showOverflow
                  },
                  on: {
                    hide: function($event) {
                      _vm.showOverflow = false
                    }
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "tooltip-target overflow-icon",
                      on: { click: _vm.onOpenSidebarNotifications }
                    },
                    [
                      _c("font-awesome-icon", {
                        staticClass: "icon",
                        attrs: { icon: "envelope-open" }
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "count-number" }, [
                        _vm._v(_vm._s(_vm.numberNotificationsUnseen))
                      ])
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }