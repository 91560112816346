var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "nb-marginalia",
      style: _vm.style,
      on: {
        click: function($event) {
          return _vm.onClick()
        },
        mouseenter: function($event) {
          return _vm.onHover(true)
        },
        mouseleave: function($event) {
          return _vm.onHover(false)
        }
      }
    },
    [
      _vm.hasHeader
        ? _c("span", {
            staticClass: "nb-marginalia-header",
            domProps: { innerHTML: _vm._s(_vm.spotlight.header) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showTime
        ? _c("span", { staticClass: "nb-marginalia-time" }, [
            _c("b", [_vm._v(_vm._s(_vm.authorName))]),
            _vm._v(" @ " + _vm._s(_vm.ago))
          ])
        : _vm._e(),
      _vm._v(
        "\n        " +
          _vm._s(
            _vm.thread.text.length > 200
              ? _vm.thread.text.substring(0, 400) + "..."
              : _vm.thread.text
          ) +
          "\n"
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }